/* vietnamese */
@font-face {
  font-family: '__Work_Sans_ad1bcd';
  font-style: italic;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/4baad49f975a4256-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Work_Sans_ad1bcd';
  font-style: italic;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/d947c0364550f2b6-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Work_Sans_ad1bcd';
  font-style: italic;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/bdfc0c838118e8d4-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
  font-family: '__Work_Sans_ad1bcd';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/0822b6067e7c0dee-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Work_Sans_ad1bcd';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/67354d9f27664b22-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Work_Sans_ad1bcd';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/6905431624c34d00-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Work_Sans_Fallback_ad1bcd';src: local("Arial");ascent-override: 83.09%;descent-override: 21.71%;line-gap-override: 0.00%;size-adjust: 111.93%
}.__className_ad1bcd {font-family: '__Work_Sans_ad1bcd', '__Work_Sans_Fallback_ad1bcd', system-ui, arial, sans
}.__variable_ad1bcd {--font-work-sans: '__Work_Sans_ad1bcd', '__Work_Sans_Fallback_ad1bcd', system-ui, arial, sans
}

@font-face {
font-family: '__feijoaMedium_8a0746';
src: url(/_next/static/media/d32107c41a7fe47b-s.p.woff2) format('woff2');
font-display: swap;
}@font-face {font-family: '__feijoaMedium_Fallback_8a0746';src: local("Arial");ascent-override: 98.03%;descent-override: 31.97%;line-gap-override: 0.00%;size-adjust: 108.84%
}.__className_8a0746 {font-family: '__feijoaMedium_8a0746', '__feijoaMedium_Fallback_8a0746', serif
}.__variable_8a0746 {--font-feijoa-medium: '__feijoaMedium_8a0746', '__feijoaMedium_Fallback_8a0746', serif
}

